<template src="./TwoFactor.html"></template>
<script>
import { mapState, mapActions } from "vuex"

import Spinner from "@/partials/Spinner/Spinner.vue"
import MiniLoader from "@/partials/MiniLoader/MiniLoader.vue";
import { required } from "vuelidate/lib/validators";

const container = {
  state: mapState({
    txn_status: state => state.twofactor.status,
    complete_status: state => state.twofactor.complete_status,
    qrdata: state => state.twofactor.data,
    prelogin: state => state.login.prelogin
  }),
  actions: mapActions({
    getqr: "twofactor/getqr",
    complete: "twofactor/complete",
  })
}

export default {
  name: "TwoFactor",
  components: {
    Spinner,
    MiniLoader
  },
  computed: {
    ...container.state
  },
  data() {
    return {
      loader: false,
      complete_2fa: false,
      show_invalid_code: false,
      form: {
        setup: ''
      },
      two_fa_email: ''
    };
  },

  validations: {
    form: {
      setup: {required}
    },
    two_fa_email: {required}
  },
  
  mounted() {    
    this.getQRCode()
  },
  methods: {
    ...container.actions,
    getQRCode() {
      var self = this

      if (!Object.keys(self.prelogin).length) {
        self.$router.push("/login");
      }

      self.loader = true
      self.getqr('two-factor/get/' + self.prelogin.user ).then(() => {
        self.loader = false
      }).catch(() => {
        self.loader = false
      })
    },
    submitComplete() {
      this.$v.form.$touch();
      if (!this.$v.form.$invalid) {
        this.send2FAGoogle()
      }
      return false;
    },
    submitCompleteEmail() {
      this.$v.two_fa_email.$touch();
      if (!this.$v.two_fa_email.$invalid) {
        this.send2FAEmail()
      }
      return false;
    },
    send2FAGoogle() {
      var self = this

      var payload = {};
      payload['google2fa_secret'] = self.qrdata.key
      payload['hash'] = self.prelogin.user
      payload['type'] = 'app'

      self.loader = true
      self.$bvModal.hide('google-modal')

      self.complete(payload).then(() => {
        if (self.complete_status) {
          self.$toast.default("2FA setup complete.")
          self.complete_2fa = true
          //self.$router.push('/account')
        }
        self.loader = false
      }).catch(() => {
        self.loader = false
      })
    },
    send2FAEmail() {
      var self = this

      var payload = {};
      payload['code'] = self.two_fa_email
      payload['hash'] = self.prelogin.user
      payload['type'] = 'email'

      self.loader = true
      self.$bvModal.hide('email-modal')

      self.complete(payload).then(() => {
        if (self.complete_status) {
          self.$toast.default("2FA setup complete.")
          self.complete_2fa = true
          //self.$router.push('/account')
        }
        self.loader = false
      }).catch((error) => {
        if (error.response.data.error_code == '2FA-001' || error.response.data.error_code == '2FA-002') {
          self.show_invalid_code = true;
        } else {            
          self.$bvModal.hide('two-fa-code-modal');
          self.$toast.default(error.response.data.message)
        }
        self.loader = false
      })
    },
    getEmailCode() {
      var self = this

      if (!Object.keys(self.prelogin).length) {
        self.$router.push("/login");
      }

      self.loader = true
      self.getqr('two-factor/get/email/' + self.prelogin.user ).then(() => {
        self.loader = false
        self.$bvModal.show('email-modal')
      }).catch(() => {
        self.loader = false
        self.$toast.default('An error occured on your request.')
      })
    }
  }
};
</script>
<style scoped src="./TwoFactor.css"></style>
