<template src="./Activity.html"></template>
<script>
import MiniLoader from "@/partials/MiniLoader/MiniLoader.vue";
import ActivityList from "@/partials/ActivityList/ActivityList.vue";
import Header from "@/partials/Header/Header.vue";

import { mapState, mapActions } from "vuex";

const container = {
  state: mapState({
    status: state => state.activity_logs.status,
    activities: state => state.activity_logs.data
  }),
  actions: mapActions({
    query: "activity_logs/query"
  })
}


export default {
  name: "Activity",
  components: {
    MiniLoader,
    ActivityList,
    Header
  },
  data() {
    return {
      show_filter: false,
      loader : false,
      page: {
        per_page: 10,
        total: 0,
        count: 0,
        current: 1
      },
      filter: {
        search : '',
        date_from: '',
        date_to: ''
      },
      qry: '',
      min_date: '',
      max_date: ''
    };
  },
  computed: {
    ...container.state,
    ...container.getters
  },
  mounted() {
      this.getActivities();
  },
  methods:  {
    ...container.actions,
    getActivities() {
      var self = this
      self.loader = true

      var qry_string = '?page=' + self.page.current + '&per_page=' + this.page.per_page + self.qry;

      self.query('activity/merchant' + qry_string).then(() => {
        self.loader = false;

        for (let key in self.activities.data) {
          let description = self.activities.data[key].description.split(', ')
          let s = '{'
          for (let k in description) {
            let d = description[k].split(': ')
            // console.log(d)
            if (s.length > 1) {
              s += ','
            }
            s += '"' + d[0] + '":"' + d[1] + '"'
          }
          s += '}'

          self.activities.data[key].description = JSON.parse(s)
        }

        self.page.total = self.activities.total;
        self.page.count = Math.ceil(
          self.activities.total / self.activities.per_page
        )
      }).catch(() => {
          self.loader = false;
      })
    },
    setMinDate() {
      const self = this
      self.min_date = self.$moment(self.filter.date_from).format('YYYY-MM-DD')
    },
    setMaxDate() {
      const self = this
      self.max_date = self.$moment(self.filter.date_to).format('YYYY-MM-DD')
    },
    setFilter() {
      const self = this;

      self.qry = self.buildFilter(self.filter)

      self.page.current = 1

      self.getActivities()
    },
    clearFilter() {
      const self = this;

      self.qry = ''

      Object.keys(self.filter).forEach(key => {
          self.filter[key] = ''
      });

      self.page.current = 1

      self.getActivities()
    }
  }
};
</script>
<style src="./Activity.css"></style>
