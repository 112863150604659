import { render, staticRenderFns } from "./TwoFactor.html?vue&type=template&id=1d2d8bae&scoped=true&external"
import script from "./TwoFactor.vue?vue&type=script&lang=js"
export * from "./TwoFactor.vue?vue&type=script&lang=js"
import style0 from "./TwoFactor.css?vue&type=style&index=0&id=1d2d8bae&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d2d8bae",
  null
  
)

export default component.exports