<template src="./SubMerchant.html"></template>
<script>
import MiniLoader from "@/partials/MiniLoader/MiniLoader.vue";
import Spinner from "@/partials/Spinner/Spinner.vue";
import Header from "@/partials/Header/Header.vue";

import { required, email } from "vuelidate/lib/validators";
import { mapState, mapActions } from "vuex";

const container = {
  state: mapState({
    status: state => state.submerchants.status,
    submerchants: state => state.submerchants.data,
    bankgroups: state => state.bankgroups.data
  }),
  actions: mapActions({
    query: "submerchants/query",
    create: "submerchants/store",
    update: "submerchants/update",
    delete: "submerchants/delete",
    query_group: "bankgroups/query"
  })
}

export default {
  name: "SubMerchant",
  components: {
    MiniLoader,
    Spinner,
    Header
  },
  data() {
    return {
      loader: true,
      generate_loader: false,
      edit_loader: false,
      delete_loader: false,
      selected_id : '',
      form : {
        first_name: '',
        last_name: '',
        email: '',
        company_name: ''
      },
      edit : {
        id:'',
        first_name: '',
        last_name: '',
        email: '',
        company_name: ''
      },
      page: {
        per_page: 10,
        total: 0,
        count: 0,
        current: 1
      },
      delete_payload: { id: ''}
    };
  },
  validations: {
    form : {
      company_name: {required},
      first_name: {required},
      last_name: {required},
      email: {email, required}
    },
    edit : {
      id: {},
      company_name: {required},
      first_name: {required},
      last_name: {required},
      email: {email, required}
    },
  },
  computed: {
    ...container.state,
    ...container.getters
  },
  mounted() {
    this.getSubMerchants()
    this.getBankGroups()
  },
  methods: {
    ...container.actions,
    getSubMerchants() {
      var self = this
      self.loader = true
      var qry_string = '?page=' + self.page.current + '&per_page=' + self.page.per_page;
      self.query("sub-merchants" + qry_string).then(() => {
        self.loader = false  

        self.page.total = self.submerchants.total;
        self.page.count = Math.ceil(
          self.submerchants.total / self.submerchants.per_page
        )  
      }).catch(() => {
        self.loader = false
      })
    },
    getBankGroups() {
      var self = this
      self.loader = true
      self.query_group("bank-groups").then(() => {
        self.loader = false
      })
    },
    sendCreate() {
      this.$v.form.$touch();
      if (!this.$v.form.$invalid) {
        this.createSubMerchant()
      }
      return false;
    },
    createSubMerchant() {
      var self = this
      self.generate_loader = true
      self
        .create(self.form)
        .then(() => {
            self.generate_loader = false;
            self.$toast.default('Sub Merchant created successfully.')
            self.$bvModal.hide('new_user');
            self.clearForms()
        }).catch((error) => {
          if (!error.response.data.success) {
            self.$toast.default(error.response.data.message);
          }
          self.generate_loader = false
          self.$bvModal.hide('new_user');
        })
    },
    showEdit(item) {
      var self = this
      self.edit.id = item.id
      self.edit.company_name = item.company
      self.edit.first_name = item.first_name
      self.edit.last_name = item.last_name
      self.edit.email = item.email

      self.$bvModal.show('edit_user')
    },
    sendUpdate() {
      this.$v.edit.$touch();
      if (!this.$v.edit.$invalid) {
        this.updateSubMerchant()
      }
      return false;
    },
    updateSubMerchant() {
      var self = this
      self.edit_loader = true
      self
        .update(self.edit)
        .then(() => {
            self.edit_loader = false;
            self.$toast.default('Sub Merchant updated successfully.')
            self.$bvModal.hide('edit_user');
            self.clearForms()
        }).catch((error) => {
          if (!error.response.data.success) {
            self.$toast.default(error.response.data.message);
          }
          self.edit_loader = false
          self.$bvModal.hide('edit_user');
        })
    },
    showDelete(item) {
      this.delete_payload.id = item.id
      this.$bvModal.show('delete_user')
    },
    deleteSubMerchant() {
      var self = this
      self.delete_loader = true
      self
        .delete(self.delete_payload.id)
        .then(() => {
            self.delete_loader = false;
            self.$toast.default('Sub Merchant deleted successfully.')
            self.$bvModal.hide('delete_user');
            self.delete_payload.id = ''
        }).catch((error) => {
          if (!error.response.data.success) {
            self.$toast.default(error.response.data.message);
          }
          self.delete_loader = false
          self.$bvModal.hide('delete_user');
        })
    },
    clearForms() {
      var self = this;
      for (var prop in self.form) {
        self.form[prop] = "";
      }

      for (var item in self.edit) {
        self.edit[item] = "";
      }

      self.$v.$reset();
    },
    onCopy () {
      this.$toast.default('Token copied!')
    }
  }
};
</script>
<style scoped src="./SubMerchant.css"></style>