<template src="./Verification.html"></template>
<script>
import { mapState, mapActions } from "vuex"

import Spinner from "@/partials/Spinner/Spinner.vue"

const container = {
  state: mapState({
    txn_status: state => state.merchants.status
  }),
  actions: mapActions({
    query: "merchants/query"
  })
}

export default {
  name: "Verification",
  components: {
    Spinner
  },
  computed: {
    ...container.state
  },
  data() {
    return {
      verify: null
    };
  },
  mounted() {
    this.checkHash()
  },
  methods: {
    ...container.actions,
    checkHash() {
      let self= this
      let token = self.$route.params.hash;

      if (token != '') {
          self.query('merchant/verify/' + token).then(() => {

            self.verify = true
            console.log(self.verify)
          }).catch(() => {
            self.verify = false
          })
      } else {
        self.verify = false
      }
      
    }
  }
};
</script>
<style scoped src="./Verification.css"></style>
