<template src="./Login.html"></template>
<script>
import Spinner from "@/partials/Spinner/Spinner.vue"
import VueRecaptcha from 'vue-recaptcha'

import { mapState, mapActions } from "vuex";
import { required, sameAs, email } from "vuelidate/lib/validators";


import 'boxicons';

const container = {
  state: mapState({
    token: state => state.login.token,
    permissions: state => state.login.permission,
    fp_status: state => state.forgot_passwords.status,
    pre_login_data: state => state.login.prelogin
  }),
  actions: mapActions({
    merchant_create: "merchants/register",
    merchant_login: "login/submit",
    forgot_password_submit: "forgot_passwords/submit",
    pre_login: "login/prelogin"
  })
};

export default {
  name: "Login",
  components: {
    VueRecaptcha,
    Spinner
  },
  data() {
    return {
      site_key: process.env.VUE_APP_GOOGLE_RECAPTCHA_KEY,
      email: "",
      password: "",
      loader: true,
      login_spinner: false,
      tfa_spinner: false,
      register_spinner: false,
      forgot_password_spinner: false,
      show_invalid_code: '',
      header_app: true,
      login: {
        email: '',
        password: ''
      },
      register: {
        company: '',
        first_name : '',
        last_name : '',
        email : '',
        password : '',
        confirm_password : "",
        recaptcha: "",
        callback_url: 'http://store.lion-gateway.com/callback_receive.php'
      },
      forgot_password: {
        email: '',
        recaptcha: ""
      },
      two_fa: '',
      recaptcha_token_error: false,
      forgot_password_recaptcha_token_error: false
    };
  },
  validations: {
    register: {
      company:{
        required
      },
      first_name:{},
      last_name:{},
      email: {
        required,
        email
      },
      password: {
        required
      },
      confirm_password: {
        sameAsPassword: sameAs("password")
      },
      recaptcha: {},
      callback_url: {}
    },
    forgot_password : {
      email: {
        required,
        email
      },
      recaptcha: {}
    },
    two_fa: {required}
  },
  computed: {
    ...container.state,
    ...container.getters
  },
  mounted() {
    this.loader = false
  },
  methods: {
    ...container.actions,
     preLoginPost() {
      let self = this;

      self.login_spinner = true
      self.pre_login(self.login)
        .then(() => {
          self.login_spinner = false
          var enabled_2fa = self.pre_login_data.enabled_2fa;
          var enable_2fa = self.pre_login_data.enable_2fa;

          if (enabled_2fa == null) {
            self.$bvModal.show('two-fa-modal');
          } else {
            if (enabled_2fa == 'email') {
              if (enable_2fa) {
                self.header_app = false
                self.$bvModal.show('two-fa-code-modal');
              } else {
                self.two_fa = self.pre_login_data.code
                self.loginPost()
              }
            }
          }
          //self.$router.push("/requests");
        }).catch((error) => {
          self.login_spinner = false
          if (error.response != undefined) {
            self.$toast.default(error.response.data.message)
          }
        })
    },
    loginPost() {
      let self = this;

      self.tfa_spinner = true
      self.show_invalid_code = ''

      var payload = {
        email: self.login.email,
        password: self.login.password,
        code: self.two_fa
      }

      self.merchant_login(payload)
        .then(() => {
          self.tfa_spinner = false
          self.$router.push("/requests");
        }).catch((error) => {
          self.tfa_spinner = false

          if (error.response.data.error_code == '2FA-001' 
              || error.response.data.error_code == '2FA-002') {
            self.show_invalid_code = error.response.data.message;
          } else {            
            self.$bvModal.hide('two-fa-code-modal');
            self.$toast.default(error.response.data.message)
          }
        })
    },
    confirmRegister() {
      this.$v.register.$touch();
      if (this.register.recaptcha == '') {
        this.recaptcha_token_error = true
      }
      if (!this.$v.register.$invalid && !this.recaptcha_token_error) {
        this.sendRegister()
      }
      return false;
    },
    sendRegister() {
      const self = this;
      self.register_spinner = true
      self.merchant_create(self.register).then(() => {
        self.register_spinner = false
        self.$bvModal.hide('register')
        self.$toast.default('Registration complete.')
        self.clearForm()
      }).catch((error) => {
        self.register_spinner = false
        self.showErrorMessage(error)
      })
    },
    clearForm() {
      var self = this
      for (var prop in self.register) {
        self.register[prop] = "";
      }
      self.$v.register.$reset();
    },
    confirmForgotPassword() {
      this.$v.forgot_password.$touch();
      if (this.forgot_password.recaptcha == '') {
        this.forgot_password_recaptcha_token_error = true
      }
      if (!this.$v.forgot_password.$invalid && !this.forgot_password_recaptcha_token_error) {
        this.sendForgotPassword()
      }
      return false;
    },
    sendForgotPassword() {
      var self = this
      self.forgot_password_spinner = true
      self.forgot_password_submit(self.forgot_password).then(() => {
        self.forgot_password_spinner = false
        self.$bvModal.hide('forgot-password')
        self.$toast.default('We sent email to reset your password.')
        self.clearForm()
      }).catch(() => {
        self.forgot_password_spinner = false
      })
    },
    onCaptchaVerified(recaptchaToken) {
      this.recaptcha_token_error = false
      this.register.recaptcha = recaptchaToken
    },
    onCaptchaExpired() {
      this.$refs.recaptcha.reset()
    },
    onFPCaptchaVerified(recaptchaToken) {
      this.forgot_password_recaptcha_token_error = false
      this.forgot_password.recaptcha = recaptchaToken
    },
    onFPCaptchaExpired() {
      this.$refs.fp_recaptcha.reset()
    },
    confirmLogin() {
      this.$v.two_fa.$touch();
      if (!this.$v.two_fa.$invalid) {
        this.loginPost()
      }
      return false;
    }
  }
};
</script>
<style src="./Login.css"></style>
