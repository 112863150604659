<template src="./Dashboard.html"></template>
<script>
//import LineChart from '@/partials/LineChart/LineChart.vue'
import MiniLoader from "@/partials/MiniLoader/MiniLoader.vue"
import Header from "@/partials/Header/Header.vue";

import Chart from 'chart.js'

import { mapState, mapActions } from "vuex";

const container = {
  state: mapState({    
    summary_deposit: state => state.requests.data,
    summary_withdrawal: state => state.withdrawals.data,
    chart : state => state.dashboards.data,
    transactions: state => state.dashboards.transactions
  }),
  actions: mapActions({
    query_request: "requests/query",
    query_withdraw: "withdrawals/query",
    query_chart: "dashboards/query",
    query_transactions: "dashboards/getTransactions"
  })
}

export default {
  name: "Dashboard",
  components: {
    Header,
    MiniLoader
  },
  data() {
    return {
      loader : false,
      renderComponent: true,
      chart_data: ''
    }
  },
  computed: {
    ...container.state,
    ...container.getters
  },
  mounted () {
    this.getChartData()
    this.getDepositSummary()
    this.getWithdrawalSummary()
    this.getLatestTransactions()
  },
  methods: {
    ...container.actions,
    getDepositSummary() {
      var self = this
      self.loader = true

      self.query_request("request/summary/2").then(() => {
        self.loader = false   
      }).catch(() => {
        self.loader = false
      })
    },
    getWithdrawalSummary() {
      var self = this
      self.loader = true

      self.query_withdraw("withdrawal/summary/2").then(() => {
        self.loader = false   
      }).catch(() => {
        self.loader = false
      })
    },
    getChartData() {
       var self = this
       self.loader = true

       self.query_chart("dashboard/chart").then(() => {         
         self.loader = false  
         self.constructData()
       }).catch(() => {
         self.loader = false
       })
    },
    loadChart() {
       const ctx = document.getElementById('planet-chart');
       new Chart(ctx, this.chart);
    },
    getLatestTransactions() {
       var self = this
       self.loader = true

       self.query_transactions("dashboard/latest-transactions").then(() => {   
         self.loader = false  
       }).catch(() => {
         self.loader = false
       })
    },
    
    constructData() {
       var self = this
       self.chart.options = {
          responsive: true, 
          aspectRatio: 3,          
          legend: {
            display: false,
            position: 'top',
            align: 'center',
            labels: {
              usePointStyle: true,
            }
          }
       };

       self.chart.hover = {
         mode: 'nearest',
         intersect: true
       };

       self.chart.scales = {
            xAxes: [{
              display: true,
              gridLines: {
                drawBorder: false,
                color: '#e7e9ed',
              },
              scaleLabel: {
                display: false,
              
              }
            }],
            yAxes: [{
              display: true,
              gridLines: {
                drawBorder: false,
                color: '#e7e9ed',
              },
              scaleLabel: {
                display: false,
              },

            }]
       };

       self.loadChart()
    }
  }
};
</script>
<style scoped src="./Dashboard.css"></style>
