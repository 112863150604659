<template src="./Landing.html"></template>
<script>
//import { mapState, mapActions } from "vuex";
import Header from "@/partials/Header/Header.vue";
export default {
  name: "Landing",
  components: {
    Header
  },
  computed: {

  },
  data() {
    return {
      merchantName: this.$merchantName,
      supportMail: this.$supportMail
    };
  },
  mounted() {
    document.body.classList.add('landing')
  }
};
</script>
<style scoped src="./Landing.css"></style>
