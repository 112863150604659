<template src="./Sidebar.html"></template>
<script>
import { mapActions } from "vuex";

const container = {
  actions: mapActions({
    logout: "login/logout"
  })
};

export default {
  name: "Sidebar",
  data() {
    return {
      show: false,
      menu_show: false,
      menu:  {
        dashboard: false,
        request: false,
        withdrawal: false,
        scheduled_scrapper: false,
        token: false,
        submerchant: false,
        users: false,
        account: false,
        activity: false,
        ip_whitelist: false,
        reports: false
      },
      active: {
        dashboard: false,
        request: false,
        withdrawal: false,
        scheduled_scrapper: false,
        token: false,
        submerchant: false,
        users: false,
        account: false,
        activity: false,
        ip_whitelist: false,
        reports: false
      },
      show_deposits: false,
      show_reports: false,
      document_path: process.env.VUE_APP_BASE + 'docs/lion_gateway_API_doc.pdf',
      merchant_type:  JSON.parse(localStorage.getItem("type"))
    }
  },
  watch: {
    $route (to) {
        this.setActiveClass(to.path)
        this.menu_show = false
    }
  },
  mounted() {
    this.setActiveClass(this.$router.currentRoute.path)
    this.$root.$on('toggleHeaderMenu', data => {
      this.menu_show = data
    })

    let info = JSON.parse(localStorage.getItem("info"))

    this.show_deposits = info.has_deposits_view
    this.show_reports = info.has_reports_view
  },
  methods: {
    ...container.actions,
    setActiveClass(path) {
      this.resetActiveClass()
      const self = this;

      if (path.indexOf('requests') > 0) {
        self.active.request = true
      } else if (path.indexOf('withdrawal') > 0) {
        self.active.withdrawal = true
      } else if (path.indexOf('/bankdeposits') >= 0)  {
        self.active.scheduled_scrapper = true
      } else if (path.indexOf('token') > 0) {
        self.active.token = true
      } else if (path.indexOf('sub-merchant') > 0) {
        self.active.submerchant = true
      } else if (path.indexOf('users') > 0) {
        self.active.users = true
      } else if (path.indexOf('account') > 0) {
        self.active.account = true
      } else if (path.indexOf('activity-logs') > 0) {
        self.active.activity = true
      } else if (path.indexOf('ipwhitelist') > 0) {
        self.active.ip_whitelist = true
      } else if (path.indexOf('reports') > 0) {
        self.active.reports = true
      } else {
        self.active.dashboard = true
      }
    },
    resetActiveClass() {
      for(const x in this.active) {
        this.active[x] = false
      }
    },
    onLogout() {
      this.logout().then(() => {
        this.$router.push("/login");
      });
    }
  }
};
</script>
<style src="./Sidebar.css"></style>
