import { _resource } from "@/api/_resource";

const state = {
  status: false,
  data: [],
  error_code: null
};

const actions = {
  async submit(context, payload) {
    await _resource.save("change-password", payload).then(response => {
      if (response) {
        context.commit("CHANGE_PASSWORD", response);
      }
    });
  }
};

const mutations = {
  CHANGE_PASSWORD: (state, data) => {
     let res = data;
    state.status = res.success;
  }
};

export const change_passwords = {
  namespaced: true,
  state,
  actions,
  mutations
};
