import { _resource } from "@/api/_resource";

const state = {
  status: false,
  data: [],
  error_code: null
};

const actions = {
  async store(context, payload) {
    await _resource.save("/merchant-user", payload).then(response => {
      if (response) {
        context.commit("MERCHANT_USER_SAVED", response);
      }
    });
  },
  async query(context, query) {
    await _resource.query(query).then(response => {
      if (response) {
        context.commit("MERCHANT_USER_FETCHED", response);
      }
    });
  },
  async update({ commit }, payload) {
    await _resource.update("/merchant-user/" + payload.id, payload).then(response => {
      if (response != undefined) {
        commit("MERCHANT_USER_UPDATED", response);
      }
    });
  },
  async delete(context, id) {
    await _resource.destroy("/merchant-user/" + id).then(response => {
      if (response) {
        context.commit("MERCHANT_USER_DELETED", response);
      }
    });
  }
};

const mutations = {
  MERCHANT_USER_SAVED: (state, data) => {
    let res = data;
    state.status = res.success;
    state.data.unshift(data.data)
  },
  MERCHANT_USER_FETCHED(state, payload) {
    let res = payload.data;
    state.data = res;
    if (payload.success) {
      state.status = payload.success;
    }
  },
  MERCHANT_USER_UPDATED: (state, data) => {
    let res = data.data;
    var index = state.data.findIndex(item => item.id === res.id)
    state.data.splice(index, 1, res)
    state.status = data.success;
  }, 
  MERCHANT_USER_DELETED: (state, data) => {
    let res = data.data;
    var index = state.data.findIndex(item => item.id === res.id)
    state.data.splice(index, 1)
    state.status = data.success;
  }
};

const getters = {
  lists: state => {
    return state.data;
  }
};

export const merchantusers = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
