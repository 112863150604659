<template src="./Users.html"></template>
<script>
import MiniLoader from "@/partials/MiniLoader/MiniLoader.vue";
import Spinner from "@/partials/Spinner/Spinner.vue";
import Header from "@/partials/Header/Header.vue";

import { required, email, sameAs } from "vuelidate/lib/validators";
import { mapState, mapActions } from "vuex";

const container = {
  state: mapState({
    status: state => state.merchantusers.status,
    users: state => state.merchantusers.data,
    roles: state => state.roles.data,
  }),
  actions: mapActions({
    query: "merchantusers/query",
    create: "merchantusers/store",
    update: "merchantusers/update",
    delete: "merchantusers/delete",
    qry_roles: "roles/query",
  })
}

export default {
  name: "Users",
  components: {
    MiniLoader,
    Spinner,
    Header
  },
  data() {
    return {
      loader: true,
      generate_loader: false,
      edit_loader: false,
      delete_loader: false,
      selected_id : '',
      form : {
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        password_confirmation: '',
        role: '',
        active: 1
      },
      edit : {
        id : '',
        first_name: '',
        last_name: '',
        email: '',
        /*password: '',
        password_confirmation: '',*/
        role: '',
        active: 1
      },
      delete_payload: { id: ''}
    };
  },
  validations: {
    form : {
      first_name: {required},
      last_name: {required},
      email: {required, email},
      password: {
        required
      },
      password_confirmation: {
        required,
        sameAsPassword: sameAs("password")
      },
      role: { required },
      active: ''
    },
    edit : {
      id : {},
      first_name: {required},
      last_name: {required},
      email: {required, email},
      /*password: {
        required
      },
      password_confirmation: {
        required,
        sameAsPassword: sameAs("password")
      },*/
      role: { required },
      active: ''
    }
  },
  computed: {
    ...container.state,
    ...container.getters
  },
  mounted() {
    this.getRoles() 
    this.getUsers()
  },
  methods: {
    ...container.actions,
    getRoles() {
      var self = this
      self.loader = true
      self.qry_roles("roles/merchant").then(() => {
        self.loader = false    
      }).catch(() => {
        self.loader = false
      })
    },
    getUsers() {
      var self = this
      self.loader = true
      self.query("merchant-users").then(() => {
        self.loader = false    
      }).catch(() => {
        self.loader = false
      })
    },
    sendCreate() {
      this.$v.form.$touch();
      if (!this.$v.form.$invalid) {
        this.createUser()
      }
      return false;
    },
    createUser() {
      var self = this
      self.generate_loader = true
      self
        .create(self.form)
        .then(() => {
            self.generate_loader = false;
            self.$toast.default('User created successfully.')
            self.$bvModal.hide('new_user');
            self.$v.form.reset()
        }).catch((error) => {
          if (!error.response.data.success) {
            self.$toast.default(error.response.data.message);
          }
          self.generate_loader = false
          self.$bvModal.hide('new_user');
        })
    },
    onCopy () {
      this.$toast.default('Token copied!')
    },
    sendRevokeToken(id) {
      this.selected_id = id
      this.$bvModal.show('revoke_token')
    },
    revokeToken() {
      var self = this
      self.revoke_loader = true;
      self.revoke(self.selected_id)
        .then(() => {
            self.revoke_loader = false;
            self.$toast.default('Token revoked successfully.')
            self.$bvModal.hide('revoke_token');
        }).catch(() => {
          self.revoke_loader = false
        })
    },
    showEdit(item) {
      var self = this
      self.edit.id = item.id
      self.edit.first_name = item.first_name
      self.edit.last_name = item.last_name
      self.edit.email = item.email
      self.edit.role = item.role_name

      self.$bvModal.show('edit_user')
    },
    sendUpdate() {
      this.$v.edit.$touch();
      if (!this.$v.edit.$invalid) {
        this.updateUser()
      }
      return false;
    },
    updateUser() {
      var self = this
      self.edit_loader = true
      self
        .update(self.edit)
        .then(() => {
            self.edit_loader = false;
            self.$toast.default('User updated successfully.')
            self.$bvModal.hide('edit_user');
            self.$v.edit.reset()
        }).catch((error) => {
          if (!error.response.data.success) {
            self.$toast.default(error.response.data.message);
          }
          self.edit_loader = false
          self.$bvModal.hide('edit_user');
        })
    },
    showDelete(item) {
      this.delete_payload.id = item.id
      this.$bvModal.show('delete_user')
    },
    deleteUser() {
      var self = this
      self.delete_loader = true
      self
        .delete(self.delete_payload.id)
        .then(() => {
            self.delete_loader = false;
            self.$toast.default('User deleted successfully.')
            self.$bvModal.hide('delete_user');
            self.delete_payload.id = ''
        }).catch((error) => {
          if (!error.response.data.success) {
            self.$toast.default(error.response.data.message);
          }
          self.delete_loader = false
          self.$bvModal.hide('delete_user');
        })
    }
  }
};
</script>
<style src="./Users.css"></style>
