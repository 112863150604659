<template src="./IPWhitelist.html"></template>
<script>
import MiniLoader from "@/partials/MiniLoader/MiniLoader.vue";
import Spinner from "@/partials/Spinner/Spinner.vue";
import Header from "@/partials/Header/Header.vue";

import { required } from "vuelidate/lib/validators";
import { mapState, mapActions } from "vuex";

const container = {
  state: mapState({
    status: state => state.ipwhitelist.status,
    ips: state => state.ipwhitelist.data
  }),
  actions: mapActions({
    query: "ipwhitelist/query",
    create: "ipwhitelist/store",
    update: "ipwhitelist/update"
  })
}

export default {
  name: "IPWhitelist",
  components: {
    MiniLoader,
    Spinner,
    Header
  },
  data() {
    return {
      loader: true,
      form: {
        ip: ''
      },
      edit: {
        ip: ''
      }
    };
  },
  validations: {
    form: {
      ip: { required }
    },
    edit: {
      ip: { required }
    }
  },
  computed: {
    ...container.state,
    ...container.getters
  },
  mounted() {
    this.getRecords() 
  },
  methods: {
    ...container.actions,
    getRecords() {
      const self = this
      self.loader = true
      self.query("ipwhitelists").then(() => {
        self.loader = false    
      }).catch(() => {
        self.loader = false
      })
    },
    sendCreate() {
      this.$v.form.$touch();
      if (!this.$v.form.$invalid) {
        this.createToken()
      }
      return false;
    },
    createToken() {
      const self = this
      self.loader = true
      self
        .create(self.form)
        .then(() => {
            self.loader = false;
            self.$toast.default('IP address created successfully.')
            self.$bvModal.hide('new_form');
            self.form.ip = ''
        }).catch(() => {
          self.loader = false
        })
    },
    sendUpdate(record) {
      this.edit = record
      this.$bvModal.show('edit_form')
    },
    updateRecord() {
      const self = this
      self.loader = true;
      self.update(self.edit)
        .then(() => {
            self.loader = false;
            self.$toast.default('IP address updated successfully.')
            self.$bvModal.hide('edit_form');
        }).catch(() => {
          self.loader = false
        })
    }
  }
};
</script>
<style scoped src="./IPWhitelist.css"></style>
