<template src="./Withdrawal.html"></template>
<script>
import MiniLoader from "@/partials/MiniLoader/MiniLoader.vue";
import FileUpload from "@/partials/FileUpload/FileUpload.vue";
import Header from "@/partials/Header/Header.vue";
import Spinner from "@/partials/Spinner/Spinner.vue";

import { mapState, mapActions } from "vuex";
import { required, minLength, minValue, maxValue, requiredIf } from "vuelidate/lib/validators";

import { _resource } from "@/api/_resource";

const container = {
  state: mapState({
    status: state => state.withdrawals.status,
    withdrawals: state => state.withdrawals.data,
    withdrawals_import: state => state.withdrawals.data_import,
  }),
  actions: mapActions({
    query: "withdrawals/query",
    withdrawal_create: "withdrawals/store",
    withdrawal_update: "withdrawals/update",
    withdrawal_import: "withdrawals/import",
    withdrawal_import_save: "withdrawals/save_import",
    withdrawal_complete: "withdrawals/complete"
  })
}

export default {
  name: "Withdrawal",
  components: {
    MiniLoader,
    Spinner,
    Header,
    FileUpload
  },
  data() {
    return {
      test: process.env.VUE_APP_TEST,
      has_permission_withdrawal_unlockstatus: false,
      is_overseas: process.env.VUE_APP_IS_OVERSEAS,
      new_withdrawal_modal_size: 'xl',
      new_withdrawal_modal_col: 'col-6',
      show_filter: false,
      loader: true,
      create_loader: false,
      update_loader: false,
      extract_loader: false,
      export_loader : false,
      save_all_loader: false,
      complete_loader: false,
      status_loader: false,
      withdrawal: {},
      createdAt: '',
      clicked: JSON.parse(localStorage.getItem("withdrawals.clicked")),
      txn_error: false,
      disable_fields: true,
      txn_error_text: '',
      create: {
        bank_name: '',
        bank_code: '',
        branch_name: '',
        branch_code: '',
        account_type: '',
        account_number: '',
        account_name: '',
        amount: '',
        transaction_id: '',
        status: 'applying',
        is_overseas: '',
        currency: 'JPY',
        iban: '',
        swift: '',
        customer_address: '',
        customer_phone: '',
        bank_address: '',
        inter_bank_name: '',
        inter_bank_address: '',
        inter_swift_code: '',
        inter_message: ''
      },
      updateStatus: {
        id: '',
        status: '',
        resend_callback: false
      },
      update : {
        id : '',
        status: '',
        notes: ''
      },
      filter: {
        search : '',
        date_from: '',
        date_to: '',
        date_completed_from: '',
        date_completed_to: ''
      },
      qry: '',
      form : {
        selected_ids: [],
        status: ''
      },
      page: {
        per_page: 10,
        total: 0,
        count: 0,
        current: 1
      },
      selected : '',
      min_date: '',
      max_date: '',
      txn_ids: [],
      export_filename: 'Lion-Gateway[User Withdrawals].csv',
    };
  },
  validations: {
    create: {
      bank_name: {required},
      bank_code: {required, minLength: minLength(4)},
      branch_name: {required},
      branch_code: {required, minLength: minLength(3)},
      account_type: {required},
      account_number: {required},
      account_name: {required},
      amount: {required, minValue: minValue(1), maxValue: maxValue(5000000) },
      transaction_id : {required},
      status: {},
      is_overseas: {},
      currency: {
        required: requiredIf(function(){
          return !this.disable_fields
        })
      },
      iban: {
        required: requiredIf(function(){
          return !this.disable_fields
        })
      },
      swift: {
        required: requiredIf(function(){
          return !this.disable_fields
        })
      },
      bank_address: {
        required: requiredIf(function(){
          return !this.disable_fields
        })
      },
      customer_address: {
        required: requiredIf(function(){
          return !this.disable_fields
        })
      },
      customer_phone: {
        required: requiredIf(function(){
          return !this.disable_fields
        })
      },
      inter_bank_name: {
        required: requiredIf(function(){
          return !this.disable_fields
        })
      },
      inter_bank_address: {
        required: requiredIf(function(){
          return !this.disable_fields
        })
      },
      inter_swift_code: {
        required: requiredIf(function(){
          return !this.disable_fields
        })
      },
      inter_message: {
        required: requiredIf(function(){
          return !this.disable_fields
        })
      }
    },
    form : {
      selected_ids: {},
      status: {required}
    }
  },
  computed: {
    ...container.state,
    selectAll: {
      set: function (value) {
        var selected = [];

        if (value) {
            this.withdrawals.data.forEach(function (withdrawal) {
              if (withdrawal.status == 'applying') {                    
                selected.push(withdrawal.id);
              }
            });
        }

        this.form.selected_ids = selected;
      },
      get: function () {
        return []
      }
    }
  },
  mounted() {
    let self = this

    this.getWithdrawals()

    let info = JSON.parse(localStorage.getItem('info'));

    self.is_overseas = info.id == self.is_overseas
    //if (self.is_overseas == '1') {
    if (self.is_overseas) {
      self.new_withdrawal_modal_size = 'xl'
      self.new_withdrawal_modal_col = 'col-6'
    } else {
      self.new_withdrawal_modal_size = 'lg'
      self.new_withdrawal_modal_col = 'col-12'
    }
  },
  methods: {
    ...container.actions,
    getWithdrawals() {
      var self = this
      self.loader = true

      const qry_string = '?page=' + self.page.current + '&per_page=' + self.page.per_page + self.qry;

      self.export_query = '/withdrawals/export-user?' + self.qry

      self.query("withdrawal/list" + qry_string).then(() => {
        self.loader = false   

        self.page.total = self.withdrawals.total;
        self.page.count = Math.ceil(
          self.withdrawals.total / self.withdrawals.per_page
        )

      }).catch(() => {
        self.loader = false
      })
    },
    sendWithdrawal(){
      this.$v.create.$touch();
      if (!this.$v.create.$invalid) {
        this.createWithdrawal()
      }
      return false;
    },
    createWithdrawal() {
      var self = this
      self.create_loader = true
      self.txt_error = false
      self
        .withdrawal_create(self.create)
        .then(() => {
            self.create_loader = false;
            self.$toast.default('Withdrawal request created successfully.')
            self.$bvModal.hide('new_withdrawal');
        }).catch((e) => {
          if (!e.response.data.success) {
            self.txn_error_text = e.response.data.message
            self.txn_error = true
          }
          self.create_loader = false
        })
    },
    sendRevokeToken(id) {
      this.selected_id = id
      this.$bvModal.show('revoke_token')
    },
    revokeToken() {
      var self = this
      self.revoke_loader = true;
      self.revoke(self.selected_id)
        .then(() => {
            self.revoke_loader = false;
            self.$toast.default('Token revoked successfully.')
            self.$bvModal.hide('revoke_token');
        }).catch(() => {
          self.revoke_loader = false
        })
    },
    sendExtract() {
      var formUpload = this.$refs.file_upload
      if (formUpload.validate()) {
        this.withdrawalExtract(formUpload)
      }
      return false
    },
    withdrawalExtract(formUpload) {
      var self = this

      let formData = new FormData()
      formData.append("import_file", formUpload.$refs.file.files[0])

      self.extract_loader = true

      self.withdrawal_import(formData).then(() => {
        self.extract_loader = false
        self.$bvModal.hide('import_token')
        self.$bvModal.show('import_summary')
      }).catch(() => {
        self.extract_loader = false
      })
    },
    saveAll() {
      var self = this
      var payload = {
        data : JSON.stringify(self.withdrawals_import)
      }

      self.save_all_loader = true

      self.withdrawal_import_save(payload).then(() => {
        self.$bvModal.hide('import_summary')
        self.save_all_loader = false
        self.getWithdrawals() 
      }).catch(() => {
        self.save_all_loader = false
      })
    },
    setSelected(item) {
      var self = this

      self.selected = item
      self.update.id = item.id
      self.update.status = item.status
      self.update.notes = item.notes

      self.$bvModal.show('view_summary');
    },
    updateWithdrawal() {
      var self = this
      self.update_loader = true
      self
        .withdrawal_update(self.update)
        .then(() => {
            self.update_loader = false;
            self.$toast.default('Withdrawal request updated successfully.')
            self.$bvModal.hide('view_summary');
        }).catch(() => {
          self.update_loader = false
        })
    },
    sendUpdate(){
      this.$v.form.$touch();
      if (!this.$v.form.$invalid) {
        this.sendComplete()
      }
      return false;
    },
    sendComplete() {
      var self = this
      self.complete_loader = true

      self.withdrawal_complete(self.form).then(() => {
        self.$bvModal.hide('modal-complete')
        self.complete_loader = false
        self.$toast.default('Update of withdrawals done.')
        self.form.selected_ids = [];
        self.form.status = '';
        self.$v.$reset();    
      }).catch((error) => {
        console.log(error)
        self.complete_loader = false
      }) 
    },
    setMinDate() {
      const self = this
      self.min_date = self.$moment(self.filter.date_from).format('YYYY-MM-DD')
    },
    setMaxDate() {
      const self = this
      self.max_date = self.$moment(self.filter.date_to).format('YYYY-MM-DD')
    },
    setFilter() {
      var self = this

      self.qry = this.buildFilter(self.filter)

      self.page.current = 1

      self.getWithdrawals()
    },
    clearFilter() {
      var self = this

      self.qry = ''

      Object.keys(self.filter).forEach(key => {
          self.filter[key] = ''
      });

      self.page.current = 1

      self.getWithdrawals()
    },
    defaultAccountName() {
      var self = this
      self.create.account_name = self.create.account_holder.replace(/[^ア-ン]+/i,'');
    },
    exportData() {
      var self = this
      self.export_loader = true
      let accessToken = localStorage.getItem("token")
      var link = process.env.VUE_APP_ROOT_API + self.export_query
      var headers = {
        'responseType' : 'arraybuffer',
        'headers' : {
          'Authorization': `Bearer ${accessToken}`
        }
      }

      self.$http.get(link, headers).then(response => {
        self.export_loader = false
        self.$bvModal.hide('export-summary')
        
        var blob = new Blob([response.data], {type: 'application/vnd.ms-excel'})
        var downloadUrl = URL.createObjectURL(blob)
        var a = document.createElement("a")
        a.href = downloadUrl

        a.download =  self.export_filename 
        
        document.body.appendChild(a)
        a.click()
      })
    },
    setBackgroundColorClass(id) {
      let self = this
      let bgcolorclass = '';

      if (!(self.clicked === null)) {
        const index = self.clicked.findIndex(data => data === id)

        if (!(index === -1)) {
          bgcolorclass = 'button_clicked'
        }
      }

      return bgcolorclass;
    },
    async setToCompleted(item) {
      const self = this

      // Save for coloring
      if (self.clicked === null) {
        self.clicked = []
        self.clicked.push(item.id)
      } else {
        const index = self.clicked.findIndex(data => data === item.id)
        if (index === -1) {
          self.clicked.push(item.id)
        }
      }

      localStorage.setItem('withdrawals.clicked', JSON.stringify(self.clicked))

      //
      self.updateStatus.resend_callback = false

      // Get the latest status
      await _resource
          .query('withdrawal/' + item.id)
          .then(response => {
            self.withdrawal = response.data

            self.createdAt = this.$moment(self.withdrawal.created_at).format("YYYY/MM/DD hh:mm A")

            self.old_status = self.withdrawal.status

            // Update form
            self.updateStatus.id = self.withdrawal.id
            self.updateStatus.status = self.withdrawal.status

            // replace the item at index to update the list
            // let index = self.withdrawals.data.findIndex(item => item.id === self.withdrawal.id)
            // self.withdrawals.data.splice(index, 1, self.withdrawal)
          })

      if (self.withdrawal.status === 'processing') {
        if (self.has_permission_withdrawal_unlockstatus) {
          this.$bvModal.show('modal-view-details');
        } else {
          let index = self.withdrawals.data.findIndex(item => item.id === self.withdrawal.id)
          self.withdrawals.data.splice(index, 1, self.withdrawal)

          self.$toast.info('This withdrawal is in process.', {
            duration: 5000
          })
        }
      } else {
        let index = self.withdrawals.data.findIndex(item => item.id === self.withdrawal.id)
        self.withdrawals.data.splice(index, 1, self.withdrawal)

        if (self.withdrawal.status === 'approved' || self.withdrawal.status === 'verifying') {
          // Update the status to processing
          let payload = {
            status: 'processing'
          }

          await _resource
              .update('withdrawal/' + item.id, payload)
              .then(response => {
                self.withdrawal = response.data

                self.updateStatus.status = self.withdrawal.status

                // replace the item at index to update the list
                // let index = self.withdrawals.data.findIndex(item => item.id === self.withdrawal.id)
                // self.withdrawals.data.splice(index, 1, self.withdrawal)
              })

          // Show the form with bank details
          this.$bvModal.show('modal-view-details');
        } else {
          if (self.withdrawal.status === 'applying' || self.withdrawal.status === 'completed') {
            this.$bvModal.show('modal-view-details');
          } else {
            // declined, cancelled
            if (self.has_permission_withdrawal_unlockstatus) {
              this.$bvModal.show('modal-view-details');
            } else {
              //self.$toast.default('This withdrawal was ' + self.withdrawal.status + '.')
            }
          }
        }
      }
    },
    async sendUpdateStatus(id, status, resend_callback = false) {
      const self = this

      if (status === 'processing') {
        status = self.old_status
      }

      let payload = {
        // id: id,
        status: status,
        resend_callback: resend_callback ? 1 : 0
      }

      self.status_loader = true

      await _resource
          .update('withdrawal/' + id, payload)
          .then(response => {
            self.withdrawal = response.data

            let index = self.withdrawals.data.findIndex(item => item.id === self.withdrawal.id)
            self.withdrawals.data.splice(index, 1, self.withdrawal)

            //self.$bvModal.hide('modal-status')
            self.$bvModal.hide('modal-view-details')
            self.status_loader = false
            //self.$toast.default('Status update done.')
          })
          .catch(() => {
            self.status_loader = false
          })
    },
  }
};
</script>
<style scoped src="./Withdrawal.css"></style>
