<template src="./Header.html"></template>
<script>
// import ModalIdle from "@/partials/ModalIdle/ModalIdle.vue"
import { mapActions } from "vuex";

const container = {
  actions: mapActions({
    logout: "login/logout"
  })
};

export default {
  name: "Header",
  props: {
    title: String    
  },
  components: {
    // ModalIdle
  },
  // computed: {
  //   isIdle() {
  //       return this.$store.state.idleVue.isIdle;
  //   }
  // },
  data() {
    return {
      menu_show: false,
      permissions: [],
      info: []
    };
  },
  mounted() {
    this.permissions = JSON.parse(localStorage.getItem("permissions"))
    this.info = JSON.parse(localStorage.getItem("info"))
  },
  beforeCreate: function() {
    document.body.className = "home";
  },
  methods: {
    ...container.actions,
    changeLang(lang) {
      localStorage.setItem('language', lang)

      this.$i18n.locale = lang
    },
    toggleMenu() {
      this.menu_show = !this.menu_show
      this.$root.$emit('toggleHeaderMenu', this.menu_show);
    }
  }
};
</script>
<style src="./Header.css"></style>
