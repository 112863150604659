<template src="./Request.html"></template>
<script>
import MiniLoader from "@/partials/MiniLoader/MiniLoader.vue";
import Header from "@/partials/Header/Header.vue";
import FileUpload from "@/partials/FileUpload/FileUpload.vue";
import { mapState, mapActions } from "vuex";

import { required, minValue, email } from "vuelidate/lib/validators";

const container = {
  state: mapState({
    status: state => state.requests.status,
    requests: state => state.requests.data,
    requests_extract: state => state.requests.extract_data
  }),
  actions: mapActions({
    query: "requests/query",
    save: "requests/store",
    deposit: "requests/deposit",
    update_request: "requests/update",
    extract_request: "requests/extract",
    save_all_request: "requests/save_all"
  })
}

export default {
  name: "Request",
  components: {
    MiniLoader,
    Header,
    FileUpload
  },
  data() {
    return {
      test: process.env.VUE_APP_TEST,
      loader: true,
      show_filter: false,
      export_loader : false,
      create_loader: false,
      update_loader: false,
      extract_loader: false,
      txn_error: false,
      txn_error_text: '',
      page: {
        per_page: 10,
        total: 0,
        count: 0,
        current: 1
      },
      filter: {
        search : '',
        date_from: '',
        date_to: '',
        date_deposited_from: '',
        date_deposited_to: '',
        status: ''
      },
      qry: '',
      create: {
        transaction_id : '',
        name : '',
        email : '',
        phone : '',
        amount : ''
      },
      update: {
        id: '',
        raw: true,
        reference_number: '',
        confirmation_number: '',
        bank_name: '',
        branch_name: '',
        branch_code: '',
        account_name: '',
        account_type: '',
        account_number: '',
        amount: ''
      },
      complete: {
        id: '',
        am_deposited: 0,
        status: '',
        notes: '',
        resend_callback: false
      },
      min_date: '',
      max_date: '',
      selected: '',
      export_filename: 'Lion-Gateway[User Payments].csv',
      error_data: [],
      csv: {
        file: ''
      },
      extract_page: {
        per_page: 10,
        total: 0,
        current: 1,
        count: 0
      },
      show_extracted: [],
      extract_fields: ['Reference Number', 'Transaction ID', 'Name', 'Email', 'Amount Deposited', 'Update to Status'],
    };
  },
  validations: {
    create: {
      transaction_id: {},
      name: {required},
      email: {required, email},
      phone: {required},
      amount: {required, minValue: minValue(1) }
    },
    update: {
      id: {},
      raw: {},
      reference_number: {required},
      confirmation_number: {required},
      bank_name: {required},
      branch_name: {required},
      branch_code: {required},
      account_name: {required},
      account_type: {required},
      account_number: {required},
      amount: {required, minValue: minValue(1) }
    },
    complete: {
      id: {},
      am_deposited: {required, minValue: minValue(1)},
      status: {},
      notes: {required},
      resend_callback: {}
    }
  },
  computed: {
    ...container.state,
    ...container.getters
  },
  mounted() {
    this.getRequests() 
  },
  methods: {
    ...container.actions,
    getRequests() {
      var self = this
      self.loader = true
      
      var qry_string = '?page=' + self.page.current + '&per_page=' + self.page.per_page + self.qry;

      self.export_query = '/requests/export-user?' + self.qry;

      self.query("request/list" + qry_string).then(() => {
        self.loader = false   

        self.page.total = self.requests.total;
        self.page.count = Math.ceil(
          self.requests.total / self.requests.per_page
        ) 
      }).catch(() => {
        self.loader = false
      })
    },
    setMinDate() {
      const self = this
      self.min_date = self.$moment(self.filter.date_from).format('YYYY-MM-DD')
    },
    setMaxDate() {
      const self = this
      self.max_date = self.$moment(self.filter.date_to).format('YYYY-MM-DD')
    },
    setFilter() {
      var self = this

      self.qry = this.buildFilter(self.filter)

      self.page.current = 1

      self.getRequests()
    },
    clearFilter() {
      var self = this

      self.qry = ''

      Object.keys(self.filter).forEach(key => {
          self.filter[key] = ''
      });

      self.page.current = 1

      self.getRequests()
    },
    exportData() {
      var self = this
      self.export_loader = true
      let accessToken = localStorage.getItem("token")
      var link = process.env.VUE_APP_ROOT_API + self.export_query
      var headers = {
        'responseType' : 'arraybuffer',
        'headers' : {
          'Authorization': `Bearer ${accessToken}`
        }
      }

      self.$http.get(link, headers).then(response => {
        self.export_loader = false
        self.$bvModal.hide('export-summary')
        
        var blob = new Blob([response.data], {type: 'application/vnd.ms-excel'})
        var downloadUrl = URL.createObjectURL(blob)
        var a = document.createElement("a")
        a.href = downloadUrl

        a.download =  self.export_filename 
        
        document.body.appendChild(a)
        a.click()
      })
    },
    sendRequest(){
      this.$v.create.$touch();
      if (!this.$v.create.$invalid) {
        this.createRequest()
      }
      return false;
    },
    createRequest() {
      var self = this
      self.create_loader = true
      self.txn_error = false
      self
        .save(self.create)
        .then(() => {
            self.create_loader = false;
            self.$toast.default('Request created successfully.')
            self.$bvModal.hide('show-create');
            self.clearForms()
        }).catch((e) => {
          if (!e.response.data.success) {
            let errText = e.response.data.message

            if (e.response.data.data) {
                errText = e.response.data.data
            }
            self.txn_error_text = errText
            self.txn_error = true
            //self.$toast.error(errText)
          }
          self.create_loader = false
        })
    },    
    sendMakeDeposit(){
      // this.$v.update.$touch();
      // if (!this.$v.update.$invalid) {
      //   this.updateRequest()
      // }
      // return false;
    },
    updateRequest() {
      // var self = this
      // self.update_loader = true
      // self
      //   .deposit(self.update)
      //   .then(() => {
      //       self.update_loader = false;
      //       self.$toast.default('Request updated successfully.')
      //       self.$bvModal.hide('show-update');
      //       self.clearForms()
      //   }).catch(() => {
      //     self.update_loader = false
      //   })
    }, 
    setUpdateSelected(item) {
      console.log(item)
      // let self = this
      //
      // self.selected = item
      //
      // self.update.id = item.id
      //
      // self.update.reference_number = item.nameId
      //
      // self.update.bank_name = item.bank.bank_name
      // self.update.branch_name = item.bank.branch_name
      // self.update.branch_code = item.bank.branch_code
      // self.update.account_name = item.bank.account_name
      // self.update.account_type = item.bank.account_type
      // self.update.account_number = item.bank.account_number
      //
      // self.$bvModal.show('show-update')
    },
    clearForms() {
      var self = this;
      for (var prop in self.create) {
        self.create[prop] = "";
      }

      for (var item in self.update) {
        self.update[item] = "";
      }

      self.update.raw = true;

      self.$v.$reset();
    },
    sendExtract() {
      var formUpload = this.$refs.file_upload
      if (formUpload.validate()) {
        this.submitExtract(formUpload)
      }
      return false
    },
    submitExtract(formUpload) {
      let self = this
      let formData = new FormData()
      formData.append("import_file", formUpload.$refs.file.files[0])

      self.import_loader = true      
      
      self.extract_request(formData).then(() => {
        self.extract_page.count = Math.ceil(
          self.requests_extract.data.length / self.extract_page.per_page
        );
        self.prepareList(0, self.extract_page.per_page)
        self.$bvModal.hide('import-request')
        self.$bvModal.show('show-extract')
        self.import_loader = false    
      }).catch((e) => {
        self.error_data = e.response.data.data
        self.import_loader = false
      })
    },
    prepareList(start, end) {
      var self = this
      self.show_extracted = self.requests_extract.data.slice(start, end)
    },
    saveAllUpdate() {
      var self = this
      var payload = {
        data : JSON.stringify(self.requests_extract.data)
      }

      self.extract_loader = true

      self.save_all_request(payload).then(() => {
        self.$bvModal.hide('show-extract')
        self.extract_loader = false
        self.getRequests()
      }).catch(() => {
        self.extract_loader = false
      })
    },
    gotoList(page) {
      var start = (page == 1) ? 0 : (page -1 ) * this.extract_page.per_page;
      var end = page * this.extract_page.per_page

      this.prepareList(start, end)
      this.extract_page.current = page
    },
    setSelected(item) {
      this.selected = item

      this.complete.id = item.id
      // this.complete.am_deposited = item.am_deposited
      this.complete.am_deposited = item.am_requested
      // this.complete.status = item.status
      this.complete.status = 'completed'
      // this.complete.notes = item.notes
      this.complete.notes = 'By ' + item.user_id

      this.$bvModal.show('payment-summary');
    },
    sendUpdate() {
      this.$v.complete.$touch();
      if (!this.$v.complete.$invalid) {
        this.completeRequest()
      }

      return false;
    },
    completeRequest() {
      const self = this
      self.update_loader = true

      self.update_request(self.complete).then(() => {
        self.update_loader = false
        self.$bvModal.hide('payment-summary');
        self.$toast.default('Payment updated.')
      }).catch(() => {
        self.update_loader = false
      })
    },
  }
}
</script>
<style src="./Request.css"></style>
