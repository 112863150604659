import { _resource } from "@/api/_resource";

const state = {
  status: false,
  data: [],
  data_import: [],
  error_code: null
};

const actions = {
  async store(context, payload) {
    await _resource.save("withdrawal", payload).then(response => {
      if (response) {
        context.commit("WITHDRAWAL_SAVED", response);
      }
    });
  },
  async import(context, payload) {
    await _resource.save("withdrawal/import", payload).then(response => {
      if (response) {
        context.commit("WITHDRAWAL_IMPORT", response);
      }
    });
  },
  async save_import(context, payload) {
    await _resource.save("withdrawal/import/save", payload).then(response => {
      if (response) {
        context.commit("WITHDRAWAL_IMPORT_SAVE", response);
      }
    });
  },
  async query(context, query) {
    await _resource.query(query).then(response => {
      if (response) {
        context.commit("WITHDRAWAL_FETCHED", response);
      }
    });
  },
  async update({ commit }, payload) {
    await _resource.update("withdrawal/" + payload.id, payload).then(response => {
      if (response != undefined) {
        commit("WITHDRAWAL_UPDATED", response);
      }
    });
  },
  async complete({ commit }, payload) {
    await _resource.save("withdrawal/user/complete", payload).then(response => {
      if (response) {
        commit("WITHDRAWAL_COMPLETE", response);
      }
    });
  }
};

const mutations = {
  WITHDRAWAL_SAVED: (state, data) => {
    let res = data;
    state.status = res.success;
    state.data.data.unshift(data.data)
  },
  WITHDRAWAL_IMPORT: (state, data) => {
    let res = data;
    state.data_import = res.data
  },
  WITHDRAWAL_FETCHED(state, payload) {
    let res = payload.data;
    state.data = res;
    if (payload.success) {
      state.status = payload.success;
    }
  },
  WITHDRAWAL_REVOKED: (state, data) => {
    let res = data.data;

    var index = state.data.findIndex(item => item.id === res.id)
    state.data.data.splice(index, 1, res)
    state.status = res.success;
  },
  WITHDRAWAL_IMPORT_SAVE: (state, data) => {
    let res = data;
    state.status = res.success;
  },
  WITHDRAWAL_UPDATED: (state, data) => {
    let res = data.data;
    var index = state.data.data.findIndex(item => item.id === res.id)
    state.data.data.splice(index, 1, res)

    state.status = data.success;
  },
  WITHDRAWAL_COMPLETE: (state, data) => {
    let res = data;
    var result = res.data.ids;
    var status = res.data.status;
    
    state.status = res.success;

    for (let i = 0; i <= result.length; i++) {
      var index = state.data.data.findIndex(item => item.id === result[i])
      if(state.data.data[index] != undefined) {

        state.data.data[index].status = status;
      }
    }
  }
};

const getters = {
  lists: state => {
    return state.data;
  }
};

export const withdrawals = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
