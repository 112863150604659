<template src="./Account.html"></template>
<script>
import MiniLoader from "@/partials/MiniLoader/MiniLoader.vue";
import Header from "@/partials/Header/Header.vue";
import Spinner from "@/partials/Spinner/Spinner.vue"

import { required, url, sameAs, minLength } from "vuelidate/lib/validators";
import { mapState, mapActions } from "vuex";

const container = {
  state: mapState({
    status: state => state.merchants.status,
    merchant: state => state.merchants.data
  }),
  actions: mapActions({
    query: "merchants/query",
    update: "merchants/update",
    change_password_submit: "change_passwords/submit"
  })
}

export default {
  name: "Account",
  components: {
    MiniLoader,
    Header,
    Spinner
  },
  data() {
    return {
      loader : true,
      change_password_loader: false,
      error_wrong_pass: false,
      account : {
        id: '',
        company : '',
        first_name : '',
        last_name : '',
        email : '',
        callback_url : '',
        mid: '',
        secret: ''
      },
      change_password : {
        old_password : '',
        password : '',
        password_confirmation : '',
      },
      role: JSON.parse(localStorage.getItem("role"))
    };
  },
  validations: {
    account: {
      id: {},
      company: { required },
      first_name: {},
      last_name: {},
      email: {},
      callback_url : { url },
      mid: {},
      secret: {}
    },
    change_password : {
        old_password : {required},
        password : {
          required,
          minLength: minLength(8)
        },
        password_confirmation : {
          sameAsPassword: sameAs("password")
        },
      }
  },
  computed: {
    ...container.state,
    ...container.getters
  },
  mounted() {
    this.getMerchantInfo()
  },
  methods: {
    ...container.actions,
    getMerchantInfo() {
      var self = this

      self.loader = true
      self.query('merchant/user').then(() => {
        self.loader = false
        self.account.id = self.merchant.id
        self.account.company = self.merchant.company
        self.account.first_name = self.merchant.first_name
        self.account.last_name = self.merchant.last_name
        self.account.email = self.merchant.email
        self.account.callback_url = self.merchant.callback_url
        self.account.mid = self.merchant.mid
        self.account.secret = self.merchant.secret
      }).catch(() => {
        self.loader = false
      })
    },
    sendUpdate() {
      this.$v.account.$touch();
      if (!this.$v.account.$invalid) {
        this.updateAccount()
      }
      return false;
    },
    updateAccount() {
      var self = this

      self.loader = true
      self.update(self.account).then(() => {
        self.loader = false
        self.$toast.default("Account Information updated.")
      }).catch(() => {
        self.loader = false
      })
    },
    sendChangePassword() {
      this.$v.change_password.$touch();
      if (!this.$v.change_password.$invalid) {
        this.submitChangePassword()
      }
      return false;
    },
    submitChangePassword() {
      var self = this
      self.change_password_loader = true;
      self.error_wrong_pass = false

      self.change_password_submit(self.change_password).then(() => {
        self.change_password_loader = false;
        self.$toast.default("Password successfully changed.")
        self.reset()
        self.$bvModal.hide('modal-change-password')
      }).catch((e) => {
        self.change_password_loader = false;
        if (!e.response.data.success) {
          console.log(e.response.data.error_code)
          if (e.response.data.error_code == 'USER-007') {
            self.error_wrong_pass = true
          }
        }
      })
    },
    reset() {
      var self = this
      Object.keys(self.change_password).forEach(key => {
          self.change_password[key] = ''
      });

      self.$v.change_password.$reset();
    }
  }
};
</script>
<style src="./Account.css"></style>
