var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[(_vm.$router.currentRoute.path != '/login' 
    && _vm.$router.currentRoute.path != '/' 
    && _vm.$router.currentRoute.path != '/404'
    && _vm.$router.currentRoute.path != '/forgot-password'
    && _vm.$router.currentRoute.path.indexOf('/reset-password') < 0
    && (_vm.$router.currentRoute.path.indexOf('/verification') < 0
    && _vm.$router.currentRoute.path.indexOf('/two-factor') < 0))?_c('div',[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('Sidebar',{ref:"sidebarMenu"}),_c('main',{staticClass:"main col-md-9 ms-sm-auto col-lg-10 px-0 overflow-hidden",attrs:{"role":"main"}},[_c('router-view')],1)],1)])]):[_c('router-view')]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }