import { _resource } from "@/api/_resource";

const state = {
  status: false,
  data: [],
  error_code: null,
  complete_status: false
};

const actions = {
  async getqr(context, query) {
    await _resource.query(query).then(response => {
      if (response) {
        context.commit("QR_FETCHED", response);
      }
    });
  },
  async complete({ commit }, payload) {
    await _resource.update('two-factor/complete', payload).then(response => {
      if (response != undefined) {
        commit("QR_COMPLETE", response);
      }
    });
  },
  async checkcode({ commit }, payload) {
    await _resource.query('two-factor/verify/' + payload.id + '/' + payload.code).then(response => {
      if (response != undefined) {
        commit("QR_CHECK", response);
      }
    });
  },
  async merchantauth_create({ commit }, payload) {
    await _resource.save("merchant-auth-code", payload).then(response => {
      if (response != undefined) {
       commit("MERCHANT_AUTH_SAVED", response);
      }
    });
  },
};

const mutations = {
  QR_FETCHED(state, payload) {
    let res = payload.data;
    state.data = res;
    if (payload.success) {
      state.status = payload.success;
    }
  },
  QR_COMPLETE: (state, data) => {
    let res = data.data;
    state.data = res;
    state.complete_status = data.success;
  },
  QR_CHECK: (state, data) => {
    let res = data.data;
    state.data = res;
    if (data.success) {
      state.status = data.success;
    }
  },
  MERCHANT_AUTH_SAVED: (state, data) => {
    let res = data;
    state.data = res.data;
    state.status = res.success;
  },
};

const getters = {
  lists: state => {
    return state.data;
  }
};

export const twofactor = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
