import Vue from "vue";
import Router from "vue-router";
import store from "@/store/";

import Login from "@/components/Login/Login";
import Dashboard from "@/components/Dashboard/Dashboard";
import Verification from "@/components/Verification/Verification";
import Request from "@/components/Request/Request";
import Token from "@/components/Token/Token";
import Withdrawal from "@/components/Withdrawal/Withdrawal";
import ScheduledScrapper from '@/components/ScheduledScrapper/ScheduledScrapper'
import Account from "@/components/Account/Account";
import Landing from "@/components/Landing/Landing";
import ResetPassword from '@/components/ResetPassword/ResetPassword'
import TwoFactor from '@/components/TwoFactor/TwoFactor'
import Users from '@/components/Users/Users'
import Activity from '@/components/Activity/Activity'
import SubMerchant from '@/components/SubMerchant/SubMerchant'
import IPWhitelist from '@/components/IPWhitelist/IPWhitelist'
import Report from '@/components/Report/Report'

import NotFound from '@/components/NotFound/NotFound'

import { i18n } from '@/main'

Vue.use(Router);

let router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      alias: "/home",
      name: "Landing",
      component: Landing
    },
    {
      path: "/login",
      name: "Login",
      component: Login
    },
    {
      path: "/reset-password/:hash",
      name: "ResetPassword",
      component: ResetPassword
    },
    {
      path: "/verification/:hash/:email?",
      name: "Verification",
      component: Verification
    },
    {
      path: "/requests",
      name: "Request",
      component: Request,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/dashboard",
      name: "Dashboard",
      component: Dashboard,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/withdrawals",
      name: "Withdrawal",
      component: Withdrawal,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/bankdeposits",
      name: "ScheduledScrapper",
      component: ScheduledScrapper,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/token",
      name: "Token",
      component: Token,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/account",
      name: "Account",
      component: Account,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/users",
      name: "Users",
      component: Users,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/activity-logs",
      name: "Activity",
      component: Activity,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/sub-merchants",
      name: "SubMerchant",
      component: SubMerchant,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/ipwhitelist",
      name: "IPWhitelist",
      component: IPWhitelist,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/two-factor",
      name: "TwoFactor",
      component: TwoFactor
    },
    {
      path: "/reports",
      name: "Report",
      component: Report,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/404',
      name: 'Not Found',
      component: NotFound
    },
    {
      path: '*',
      redirect: '/404'
    },
  ]
});

router.beforeEach((to, from, next) => {
  i18n.locale = localStorage.getItem('language') || 'en'
  let token = localStorage.getItem("token")
  if (token !== undefined) {
    store.state.token = token
  } else {
    store.state.token = null
  }

  if (to.meta.requiresAuth) {
    if (token) {
      if (to.name == 'SubMerchant') {
        let type = JSON.parse(localStorage.getItem("type"))
        if (type !== 1) {
          next("/dashboard");
          return;      
        }
      }
      next();
      return;
    }
    next("/login");
  } else {
    next();
  }
});

export default router;
