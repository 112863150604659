<template src="./NotFound.html"></template>
<script>
import Header from "@/partials/Header/Header.vue";

export default {
  name: "Not Found",
  components: {
    Header
  },
  data() {
    return {
      // home: process.env.VUE_APP_BASE + 'dashboard'
    }
  },
}
</script>
<style scoped src="./NotFound.css"></style>
