import Vue from "vue";
import Vuex from "vuex";

import { login } from "./Login/login.store"
import { merchants } from "./Merchants/merchant.store"
import { requests } from "./Requests/request.store"
import { tokens } from "./Tokens/token.store"
import { withdrawals } from "./Withdrawals/withdrawal.store"
import { scheduledscrapper } from "./ScheduledScrapper/scheduled-scrapper.store"
import { forgot_passwords } from "./ForgotPassword/forgot-password.store"
import { change_passwords } from "./ChangePassword/change-password.store"
import { roles } from "./Roles/role.store"
import { twofactor } from "./TwoFactor/two-factor.store"
import { merchantusers } from "./MerchantUsers/merchant-user.store"
import { dashboards } from "./Dashboard/dashboard.store"
import { activity_logs } from "./ActivityLogs/activity-logs.store"
import { submerchants } from "./SubMerchant/submerchant.store"
import { bankgroups } from "./BankGroup/bank-groups.store"
import { ipwhitelist } from "./IPWhitelist/ipwhitelist"


Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    login,
    merchants,
    requests,
    tokens,
    withdrawals,
    scheduledscrapper,
    forgot_passwords,
    change_passwords,
    twofactor,
    roles,
    merchantusers,
    dashboards,
    activity_logs,
    submerchants,
    bankgroups,
    ipwhitelist
  }
})
