<template src="./ResetPassword.html"></template>
<script>
import { mapState, mapActions } from "vuex"

import Spinner from "@/partials/Spinner/Spinner.vue"
import { required, sameAs, minLength } from "vuelidate/lib/validators";

const container = {
  state: mapState({
    txn_status: state => state.merchants.status
  }),
  actions: mapActions({
    query: "merchants/query",
    reset_password: "merchants/resetPassword"
  })
}

export default {
  name: "Verification",
  components: {
    Spinner
  },
  computed: {
    ...container.state
  },
  data() {
    return {
      verify: null,
      reset_password_spinner: false,
      reset_success: false,
      form: {
        password: "",
        password_confirmation: "",
        hash: ""
      }
    };
  },
  validations: {
    form: {
      password: {
        required,
        minLength: minLength(8)
      },
      password_confirmation: {
        sameAsPassword: sameAs("password")
      }
    }
  },
  mounted() {
    this.form.hash = this.$route.params.hash;
    this.verifyHash()
  },
  methods: {
    ...container.actions,
    verifyHash() {
      let self = this;
      self.loader = true;

      self
        .query("reset-password/" + self.form.hash)
        .then(() => {
          self.loader = false
          self.verify = self.txn_status
        })
        .catch(() => {
          self.loader = false
          self.invalidHash = true
          self.verify = false
        });
    },
    confirmResetPassword() {
      this.$v.form.$touch();
      if (!this.$v.form.$invalid) {
        this.sendResetPassword();
      }
      return false;
    },
    sendResetPassword() {
      var self = this
      self.reset_password_spinner = true

      self.reset_password(self.form).then(() => {
        self.reset_password_spinner = false
        self.$toast.default('Password has been reset.')
        self.reset_success = true
        setTimeout(function(){
          self.$router.push({ name: 'Login' });
        }, 3000);
      }).catch(() => {
        self.reset_password_spinner = false
      });
    }
  }
};
</script>
<style scoped src="./ResetPassword.css"></style>
