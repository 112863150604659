<template src="./Token.html"></template>
<script>
import MiniLoader from "@/partials/MiniLoader/MiniLoader.vue";
import Spinner from "@/partials/Spinner/Spinner.vue";
import Header from "@/partials/Header/Header.vue";

import { required } from "vuelidate/lib/validators";
import { mapState, mapActions } from "vuex";

const container = {
  state: mapState({
    status: state => state.tokens.status,
    tokens: state => state.tokens.data
  }),
  actions: mapActions({
    query: "tokens/query",
    create: "tokens/store",
    revoke: "tokens/revoke"
  })
}

export default {
  name: "Token",
  components: {
    MiniLoader,
    Spinner,
    Header
  },
  data() {
    return {
      loader: true,
      generate_loader: false,
      revoke_loader: false,
      selected_id : '',
      form : {
        name: ''
      }
    };
  },
  validations: {
    form: {
      name: { required }
    }
  },
  computed: {
    ...container.state,
    ...container.getters
  },
  mounted() {
    this.getTokens() 
  },
  methods: {
    ...container.actions,
    getTokens() {
      var self = this
      self.loader = true
      self.query("token/list").then(() => {
        self.loader = false    
      }).catch(() => {
        self.loader = false
      })
    },
    sendCreate() {
      this.$v.form.$touch();
      if (!this.$v.form.$invalid) {
        this.createToken()
      }
      return false;
    },
    createToken() {
      var self = this
      self.generate_loader = true
      self
        .create(self.form)
        .then(() => {
            self.generate_loader = false;
            self.$toast.default('Token created successfully.')
            self.$bvModal.hide('new_token');
            self.form.name = ''
        }).catch(() => {
          self.generate_loader = false
        })
    },
    onCopy () {
      this.$toast.default('Token copied!')
    },
    sendRevokeToken(id) {
      this.selected_id = id
      this.$bvModal.show('revoke_token')
    },
    revokeToken() {
      var self = this
      self.revoke_loader = true;
      self.revoke(self.selected_id)
        .then(() => {
            self.revoke_loader = false;
            self.$toast.default('Token revoked successfully.')
            self.$bvModal.hide('revoke_token');
        }).catch(() => {
          self.revoke_loader = false
        })
    }
  }
};
</script>
<style scoped src="./Token.css"></style>
