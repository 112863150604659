import { _resource } from "@/api/_resource";

const state = {
  token: null,
  permissions: [],
  info: [],
  prelogin: [],
  role: '',
  type: 0
};

const actions = {
  async submit(context, payload) {
    await _resource.save("merchant-login", payload).then(response => {
      if (response) {
        context.commit("LOGIN_SUBMIT", response);
      }
    });
  },
  async prelogin(context, payload) {
    await _resource.save("pre-login", payload).then(response => {
      if (response) {
        context.commit("PRE_LOGIN_SUBMIT", response);
      }
    });
  },
  userinfo(context) {
    context.commit("LOGIN_INFO")
  },
  logout(context) {
    context.commit("LOGOUT_SUBMIT");
  }
};

const mutations = {
  PRE_LOGIN_SUBMIT : (state, data) => {
    state.prelogin = data.data
  },
  LOGIN_SUBMIT: (state, data) => {
    let res = data.data;
    state.token = null;
    state.permissions = [];

    if (data.success) {
      state.token = res.access_token
      state.permissions = res.permissions
      state.info = res.merchant
      state.role = res.role

      localStorage.setItem("token", res.access_token)
      localStorage.setItem("permissions", JSON.stringify(res.permissions))
      localStorage.setItem("info", JSON.stringify(res.merchant))
      localStorage.setItem("role", JSON.stringify(res.role))
      localStorage.setItem("type", JSON.stringify(res.type))
    }
  },
  LOGIN_INFO: (state) => {
    state.info = JSON.parse(localStorage.getItem("info"))
    state.permissions = JSON.parse(localStorage.getItem("permissions"))
  },
  LOGOUT_SUBMIT: (state) => {
    state.token = null
    state.permissions = []
    state.info = []

    localStorage.removeItem("token")
    localStorage.removeItem("permissions")
    localStorage.removeItem("info")
    localStorage.removeItem("role")
    localStorage.removeItem("type")
  },
  LOGIN_RESET: (state) => {
    Object.keys(state).forEach(key => {
      state[key] = null
    })
  }
};

export const login = {
  namespaced: true,
  state,
  actions,
  mutations
};
